export default [
  slide("Bienvenidos", "DSC_7018.jpg"),
  slide("Nuestros Coros", "DSC_4012.jpg", "/coros"),
  slide("Nuestros Templos", "DSC_7064.jpg", "/nuestros-templos"),
  slide("Estudios Bíblicos", "DSC04268.jpg", "/estudios-biblicos")
];

function slide(title, image, url) {
  return {
    title,
    image,
    url
  };
}
