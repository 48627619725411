import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { Link } from "gatsby";

const DivContainer = styled.div`
  position: relative;
`;

const LinkContainer = styled(Link)`
  display: block;
  position: relative;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 60%;
  right: -50%;
  background-color: rgba(0, 0, 0, 0.3);
  transform: skew(-15deg, 0deg);
`;

const Title = styled.div`
  position: absolute;
  top: 50%;
  left: 80%;
  color: #fff;
  font-size: 32px;
  transform: translate(-50%, -50%);

  ${props => props.theme.breakpoints.down("sm")} {
    font-size: 24px;
  }

  @media (max-width: 649.95px) {
    font-size: 18px;
  }
`;

export default ({ image, title, url, height }) => {
  const Container = url ? LinkContainer : DivContainer;

  return (
    <Container to={url} style={{ height }}>
      <Img fluid={image.fluid} />
      <Background />
      <Title>{title}</Title>
    </Container>
  );
};
