import React from "react";
import Slider from "react-slick";
import { StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Window from "@tleef/react-window";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CarouselItem from "./CarouselItem";

import slides from "../content/data/carousel";

const StyledSlider = styled(Slider)`
  margin-top: 140px;
  width: 100%;

  @media (max-width: 649.95px) {
    margin-top: 80px;
  }
`;

export default class Carousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      height:
        typeof window !== "undefined" ? Math.round(window.innerWidth * 0.3) : 0
    };
  }

  componentDidMount() {
    this.onWindowResize();
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1000,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: true,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <StaticQuery
        query={query}
        render={data => {
          const { height } = this.state;

          const images =
            data &&
            data.images &&
            data.images.edges &&
            data.images.edges.map(edge => {
              return edge.node.childImageSharp;
            });

          if (!images || !images.length) {
            return;
          }

          // force re-render
          const k = Math.random().toString(36).substring(7);

          return (
            <Window onResize={() => this.onWindowResize()}>
              <StyledSlider key={k} {...settings} style={{ height }}>
                {slides.map(slide => this.renderSlide(slide, images))}
              </StyledSlider>
            </Window>
          );
        }}
      />
    );
  }

  renderSlide(slide, images) {
    const image = images.find(image => {
      return image.fluid.originalName === slide.image;
    });

    if (!image) {
      return;
    }

    return <CarouselItem image={image} title={slide.title} url={slide.url} />;
  }

  onWindowResize() {
    this.setState({
      height: Math.round(window.innerWidth * 0.3)
    });
  }
}

export const query = graphql`
  query {
    images: allFile(filter: { sourceInstanceName: { eq: "carousel" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 2560, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              originalName
            }
          }
        }
      }
    }
  }
`;
