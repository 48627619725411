import React, { Component } from "react";
import styled from "styled-components";
import Grid from "@tleef/react-grid";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Header from "../components/Header";
import Content from "../components/Content";
import Carousel from "../components/Carousel";
import Footer from "../components/Footer";
import showdown from "showdown";
import Heading from "../components/Heading";
import SideBar from "../components/SideBar";

const converter = new showdown.Converter();

const saludos1 = `Tengo el honor y privilegio de parte de nuestro Dios, el poder usar este medio para dirigirme a Uds., amados Pastores, Pastoras, Hermanas y Hermanos, como a los amigos de nuestra Iglesia Remanente Metodista Pentecostal. y en esta ocasión, hacerlo a través de este medio, que es la página oficial de nuestra Misión.

En el mes de Marzo del año 2012, en el desarrollo de nuestra convención, “bajo la voluntad del Señor”, el que pudiéramos dar inicio al trabajo que hoy  se ha transformado en una gloriosa realidad.

Quiero en esta primera edición invitarlos a recorrer nuestra página, en la cuales hemos querido en primer lugar exaltar a nuestro Dios por los años que nos ha concedido, y  mostrarle en parte lo que ha sido nuestra historia.

En cada una de las paginas están plasmados eventos que han ido marcando el desarrollo de nuestro pasado, presente, y futuro del acontecer de nuestras Iglesias en nuestro País y en el extranjero, por lo que invito a nuestros Pastores a poner a disposición, un hermano corresponsal por cada Iglesia, para que sea el canal por el cual será enviada toda la información, para cada una de las ediciones que tendremos en el futuro con la ayuda de nuestro Dios.

A la verdad somos una Iglesia naciente, que solo por la gracia de nuestro Señor hemos ido dando zancadas muy seguras, y en cada una de ellas viendo el gran respaldo de Dios a nuestra labor, la cual en estos años de existencia, queremos conservar los principios y elementos de nuestra doctrina, y los fundamentos de nuestra fe, en el marco de dar un notorio realce al pentecostalismo en Chile, y para ello  hare mención de estos hitos importantes que fueron dando el marco de la historia del pentecostalismo en nuestro País, como así también, compartirles hermosos temas que tienen relevancia desde el punto de vista cristiano del acontecer en nuestra Patria en el mundo entero, colocando en ello una mirada personal, a la lupa de las Sagradas Escrituras.

Solo deseo que este medio pueda bendecir a todos nuestros hermanos, y también a cada lector a quien llegue nuestra página, la que tendrá un profundo y glorioso material espiritual de lectura.

Saludos fraternos a quienes fueron nominados para trabajar en este proyecto, a quienes se integraran, para hacer cada vez más grande el nombre de nuestro gran Dios, a nuestro Señor Jesucristo, y el Espíritu Santo.  Ya que este es el primer fin de este lanzamiento.

Para el Señor nuestro Dios Todopoderoso sea la gloria, la honra para siempre Amen.

Dios los Bendiga.

Daniel Rodríguez V.<br/>
Obispo Presidente<br/>
Iglesia Remanente Metodista Pentecostal 
`;
const saludos2 = `Pastoras Hermanas y amigas en Cristo, como a todos los varones  que ven esta página de nuestra misión, ya sea en Chile o el Extranjero

Reciban Uds. el más afectuoso saludo de bienvenida. Espero que sea de mucha ayuda y bendición para todos. 

Al comenzar, deseo darle todo el honor y gloria a nuestro Gran Dios Todopoderoso, por permitirnos este medio de  comunicación,  que será de provecho espiritual y en todas las áreas de la vida.

Tomare en el futuro por este medio, temas concernientes a la mujer, la compañera que Dios hizo.

A través de las ediciones futuras, iremos hablando de los dones y talentos que Dios puso en la mujer, de cuyo resultado se manifiesta la esposa idónea, madre virtuosa, y la buena trabajadora en la viña del Señor.

Compartiremos experiencias como hijas de Dios, en la labor que el Señor nos ha encomendado, para edificación de unas y otras.

El cariño de mi parte para nuestros Pastores y en especial a nuestro Obispo, quienes tienen la misión de guiar las ovejas del Señor, y para quienes tienen la misión de editar este medio de comunicación, que la gracia del Señor y buena voluntad sea con ellos.

Ricas Bendiciones para todos.

María Verónica Escobar de R.<br/>
Pastora y Diaconisa de I.R.M.P.
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;
`;

const Saludos = styled.div`
  margin-top: 20px;
  margin-bottom: 40px;
`;

class IndexPage extends Component {
  render() {
    return (
      <Layout>
        <Seo title="Inicio" keywords={[`iglesia`]} />
        <Container>
          <Header />
          <Carousel />
          <Content spacer={false}>
            <Inner>
              <Grid container spacing={40}>
                <Grid item xs={12} md={8}>
                  <Heading>Bienvenidos</Heading>
                  <h3>
                    Saludos de nuestro Obispo Presidente Rev. Daniel Rodriguez
                    Vergara
                  </h3>
                  <Saludos
                    dangerouslySetInnerHTML={{
                      __html: converter.makeHtml(saludos1)
                    }}
                  />
                  <h3>
                    Saludos de nuestra Pastora y Diaconisa María Verónica
                    Escobar de R.
                  </h3>
                  <Saludos
                    dangerouslySetInnerHTML={{
                      __html: converter.makeHtml(saludos2)
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SideBar fixTop={180} fixBottom={140} spacer={false} />
                </Grid>
              </Grid>
            </Inner>
          </Content>
          <Footer />
        </Container>
      </Layout>
    );
  }
}

export default IndexPage;
